import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import styled from 'styled-components';
import BlastWaveLogo from './BlastWaveLogo';
import Register from './Register';
import { Title } from './style';
import Success from './Success';
import './Swipe.css';
import Welcome from './Welcome';

const Root = styled.div`
    width: 100%;
    min-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

const Header = styled.h1`
    box-sizing: border-box;
    margin: 0px;
    width: 100%;
    padding-left: 32px;
    padding-top: 32px;
    text-align: left;
    font-weight: 400;
`;

const Footer = styled.h1`
    box-sizing: border-box;
    margin: 0px;
    width: 100%;
    padding-left: 32px;
    padding-bottom: 32px;
    text-align: left;
`;

const Spacer = styled.div`
    flex-grow: 1;
`;

const Content = styled.main`
    display: grid;
    grid-template: 'main';
    overflow-x: hidden;
    > * {
        grid-area: main;
        position: relative;
        width: 100vw;
        box-sizing: border-box;
    }
`;

const ErrorContainer = styled.div`
    text-align: center;
    padding-left: 100px;
    padding-right: 100px;
    box-sizing: border-box;
`;

const ErrorMessage = ({ message }) => {
    return (
        <ErrorContainer>
            <Title>
                {message}
                <br />
                <br />
                Please contact your network administrator
            </Title>
        </ErrorContainer>
    );
};

const RegistrationRoutes = ({ invitation }) => {
    const location = useLocation();

    if (!invitation) {
        return <ErrorMessage message='No invitation provided' />;
    }

    return (
        <TransitionGroup component={Content}>
            <CSSTransition key={location.pathname} classNames='swipe' timeout={5000}>
                <Routes location={location}>
                    <Route path='/' element={<Welcome />} />
                    <Route path='register' element={<Register invitation={invitation} />} />
                    <Route path='success' element={<Success />} />
                </Routes>
            </CSSTransition>
        </TransitionGroup>
    );
};

const useHash = () => {
    const [hash, setHash] = React.useState(window.location.hash);
    const listenToPopstate = () => setHash(window.location.hash);
    React.useEffect(() => {
        window.addEventListener('popstate', listenToPopstate);
        return () => {
            window.removeEventListener('popstate', listenToPopstate);
        };
    }, []);
    return hash;
};

const App = () => {
    const hash = useHash();
    const [invitation, setInvitation] = useState(null);

    useEffect(() => {
        if (!hash) return;
        setInvitation(hash.substring(1));
    }, [hash]);

    return (
        <Root>
            <Header>
                <b>Blast</b>Shield™
            </Header>
            <Spacer />
            <Router>
                <RegistrationRoutes invitation={invitation} />
            </Router>
            <Spacer />
            <Footer>
                <a href='https://www.blastwave.com' target='_blank' rel='noreferrer'>
                    <BlastWaveLogo />
                </a>
            </Footer>
        </Root>
    );
};

export default App;
