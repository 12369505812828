import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { AppStoreButton, Button, GooglePlayButton, Title } from './style';

const Root = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 32px;
`;

const AppLinks = styled.div`
    display: flex;
    gap: 32px;
    margin: 32px;
`;

const CenteredParagraph = styled.p`
    text-align: center;
`;

const PLATFORM_ANDROID = 1;
const PLATFORM_IOS = 2;
const PLATFORM_OTHER = 3;

const getPlatform = () => {
    // @ts-ignore
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/android/i.test(userAgent)) {
        return PLATFORM_ANDROID;
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    // @ts-ignore
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return PLATFORM_IOS;
    }

    return PLATFORM_OTHER;
};

const Welcome = () => {
    const navigate = useNavigate();
    const platform = getPlatform();

    switch (platform) {
        case PLATFORM_ANDROID:
        case PLATFORM_IOS:
            return (
                <Root>
                    <Title>Welcome</Title>
                    <CenteredParagraph>
                        To register using this link you need to have the <b>BlastShield™ Authenticator</b> app installed.
                        Please download it from below:
                    </CenteredParagraph>
                    <AppLinks>
                        {platform === PLATFORM_ANDROID ? (
                            <a
                                href='https://play.google.com/store/apps/details?id=io.blastwave.authenticator'
                                target='_blank'
                                rel='noreferrer'
                            >
                                <GooglePlayButton />
                            </a>
                        ) : (
                            <a
                                href='https://apps.apple.com/us/app/blastshield-authenticator/id1534769533'
                                target='_blank'
                                rel='noreferrer'
                            >
                                <AppStoreButton />
                            </a>
                        )}
                    </AppLinks>

                    <Button onClick={() => window.open(window.location.toString())}>OPEN IN APP</Button>
                </Root>
            );
        case PLATFORM_OTHER:
            return (
                <Root>
                    <Title>Welcome</Title>
                    <p>
                        Please make sure you have the <b>BlastShield™ Authenticator</b> app installed on your mobile device
                        before you continue:
                    </p>
                    <AppLinks>
                        <a
                            href='https://play.google.com/store/apps/details?id=io.blastwave.authenticator'
                            target='_blank'
                            rel='noreferrer'
                        >
                            <GooglePlayButton />
                        </a>
                        <a
                            href='https://apps.apple.com/us/app/blastshield-authenticator/id1534769533'
                            target='_blank'
                            rel='noreferrer'
                        >
                            <AppStoreButton />
                        </a>
                    </AppLinks>
                    <Button onClick={() => navigate('/register')}>Continue</Button>
                </Root>
            );
        default:
            return <span />;
    }
};

export default Welcome;
