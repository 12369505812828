import React, { useState } from 'react';
import styled from 'styled-components';
import { AppStoreButton, GooglePlayButton, Subtitle, Title } from './style';
import { ReactComponent as AppleLogo } from './Apple.svg';
import { ReactComponent as WindowsLogo } from './Windows.svg';
import { ReactComponent as LinuxLogo } from './Linux.svg';
import Modal from './Modal';
import { Clipboard } from 'react-feather';
import CopyToClipboard from 'react-copy-to-clipboard';

const Root = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const DownloadButtons = styled.div`
    display: flex;
    flex-direction: column;
    gap: 48px;
    background: #00000080;
    border: 3px solid white;
    border-radius: 16px;
    padding: 16px;
    margin: 16px;
`;

const DownloadButtonsRow = styled.div`
    display: flex;
    gap: 48px;
    justify-content: center;
`;

const DownloadButtonRoot = styled.a`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-weight: 700;
    font-size: 16px;
    background: rgba(0, 0, 0, 0);
    color: white;
    text-decoration: none;
    /* opacity: 0.5; */
    cursor: pointer;
    &:hover {
        color: #a6a6a6;
    }
    transition: color 400ms ease;
`;

// const DownloadButtonRoot = styled.a`
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     flex-direction: column;
//     font-weight: 700;
//     font-size: 16px;
//     border: 1px solid #a6a6a6;
//     border-radius: 8px;
//     background: black;
//     padding: 8px;
//     color: white;
//     text-decoration: none;
//     cursor: pointer;
//     &:hover {
//         background: white;
//         color: black;
//     }
//     transition: background 400ms, color 400ms ease;
// `;

const InstallTitle = styled(Title)`
    text-align: center;
    margin-top: 0px;
`;

const InstallContent = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;

const DownloadButton = ({ logo, caption, ...rest }) => {
    const Logo = logo;
    return (
        <DownloadButtonRoot {...rest}>
            <Logo style={{ width: 96, height: 96, margin: 8 }}></Logo>
            {caption}
        </DownloadButtonRoot>
    );
};

const TransparentButton = styled.button`
    background: none;
    border: none;
    color: white;
    cursor: pointer;
`;

const Success = () => {
    const [linuxInstructionsOpen, setLinuxInstructionsOpen] = useState(false);

    return (
        <Root>
            <Title>Registration successful</Title>
            <Subtitle>
                You should now be able to connect using a <b>BlastShield™</b> app
            </Subtitle>
            <Subtitle>
                Don't have a <b>BlastShield™</b> app installed? Get one here:
            </Subtitle>
            <DownloadButtons>
                <DownloadButtonsRow>
                    <DownloadButton
                        logo={AppleLogo}
                        caption='macOS'
                        href='https://dl.blastwave.io/client/BlastShield.pkg'
                    />
                    <DownloadButton
                        logo={WindowsLogo}
                        caption='Windows'
                        href='https://dl.blastwave.io/client/BlastShield.msi'
                    />
                    <DownloadButton logo={LinuxLogo} caption='Linux' onClick={() => setLinuxInstructionsOpen(true)} />
                </DownloadButtonsRow>
                <DownloadButtonsRow>
                    <a
                        href='https://play.google.com/store/apps/details?id=io.blastwave.blastshield'
                        target='_blank'
                        rel='noreferrer'
                    >
                        <GooglePlayButton />
                    </a>
                    <a href='https://apps.apple.com/gb/app/blastshield/id1554556259' target='_blank' rel='noreferrer'>
                        <AppStoreButton />
                    </a>
                </DownloadButtonsRow>
            </DownloadButtons>
            <Modal open={linuxInstructionsOpen} onClose={() => setLinuxInstructionsOpen(false)}>
                <InstallTitle>Install by running this command</InstallTitle>
                <InstallContent>
                    <code>curl https://dl.blastwave.io/client/install.sh | bash</code>
                    <CopyToClipboard text='curl https://dl.blastwave.io/client/install.sh | bash'>
                        <TransparentButton>
                            <Clipboard size={24} />
                        </TransparentButton>
                    </CopyToClipboard>
                </InstallContent>
            </Modal>
        </Root>
    );
};

export default Success;
