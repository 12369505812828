import styled from 'styled-components';
import { ReactComponent as GooglePlayIcon } from './GooglePlay.svg';
import { ReactComponent as AppStoreIcon } from './AppStore.svg';

const Title = styled.h1``;

const Subtitle = styled.p`
    margin-top: 0px;
    margin-bottom: 16px;
    font-size: 16px;
`;

const Button = styled.button`
    background-color: #00000080;
    color: white;
    border: none;
    border-radius: 8px;
    padding: 10px 20px 10px 20px;
    font-weight: 700;
    font-size: 18px;

    &:hover {
        background-color: white;
        color: black;
    }

    transition: background-color 400ms, color 400ms ease;
`;

const GooglePlayButton = styled(GooglePlayIcon)`
    width: 192px;
    height: 57px;
    &:hover .st0 {
        fill: white;
        transition: fill 400ms ease-out;
    }
    &:not(hover) .st0 {
        fill: black;
        transition: fill 400ms ease-out;
    }

    &:hover .st2,
    &:hover .st3 {
        fill: black;
        transition: fill 400ms ease-out;
    }
    &:not(hover) .st2,
    &:not(hover) .st3 {
        fill: white;
        transition: fill 400ms ease-out;
    }
`;

const AppStoreButton = styled(AppStoreIcon)`
    width: 192px;
    height: 57px;
    &:hover {
        fill: white;
        transition: fill 400ms ease-out;
    }
    &:not(hover) {
        fill: black;
        transition: fill 400ms ease-out;
    }

    &:hover .fg {
        fill: black;
        transition: fill 400ms ease-out;
    }
    &:not(hover) .fg {
        fill: white;
        transition: fill 400ms ease-out;
    }
`;

export { Title, Subtitle, Button, GooglePlayButton, AppStoreButton };
