import React, { useCallback, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import styled from 'styled-components';
import './Fade.css';

const Backdrop = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #000000c0;

    z-index: 1000;
`;

const Frame = styled.div`
    border: 4px solid white;
    background: #000000a0;
    padding: 16px 32px;
    border-radius: 16px;
`;

const Modal = ({ open, onClose, children }) => {
    const handleKeyPress = useCallback(
        (event) => {
            if (open && event.key === 'Escape') onClose();
        },
        [open, onClose]
    );

    useEffect(() => {
        document.addEventListener('keydown', handleKeyPress);

        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, [handleKeyPress]);

    return (
        <CSSTransition in={open} classNames='fade' timeout={250} unmountOnExit>
            <Backdrop onClick={() => onClose()}>
                <Frame onClick={(event) => event.stopPropagation()}>{children}</Frame>
            </Backdrop>
        </CSSTransition>
    );
};

export default Modal;
