import React from 'react';

function BlastWaveLogo() {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='192' height='43' version='1.1' viewBox='0 0 176.178 43'>
            <path
                fill='#FFF'
                d='M46.544 11.369h9.892c3.496 0 5.912 2.047 5.912 4.889 0 1.99-.995 3.439-2.729 4.349 2.274 1.023 3.582 2.842 3.582 5.003 0 3.127-2.672 5.287-6.396 5.287H46.544V11.369zm8.862 7.891c1.559 0 2.664-.907 2.664-2.154 0-1.219-1.105-2.126-2.664-2.126h-4.649v4.28h4.649zm.637 8.091c1.667 0 2.854-.989 2.854-2.374 0-1.385-1.187-2.374-2.854-2.374h-5.285v4.748h5.285z'
            ></path>
            <path fill='#fff' d='M63.998 11.034H68.14399999999999V30.896H63.998z'></path>
            <path
                fill='#fff'
                d='M82.723 22.355v8.541h-4.012v-1.477c-1.053 1.193-2.732 1.818-4.326 1.818-3.131 0-5.549-1.874-5.549-4.686 0-2.897 2.704-4.885 6.119-4.885 1.195 0 2.504.256 3.757.738v-.284c0-1.562-.968-2.925-3.387-2.925-1.366 0-2.646.454-3.813 1.051l-1.394-2.755c2.105-1.065 4.151-1.634 6.228-1.618 3.538.026 6.377 2.942 6.377 6.482m-4.012 3.933v-1.363a9.282 9.282 0 00-3.153-.54c-1.505 0-2.698.88-2.698 2.073s1.051 1.931 2.47 1.931c1.592 0 3.097-.738 3.381-2.101'
            ></path>
            <path
                fill='#fff'
                d='M83.149 29.042l1.789-2.868c1.306.937 2.953 1.647 4.913 1.647 1.107 0 1.903-.454 1.903-1.108 0-.909-1.079-1.193-2.584-1.59-3.01-.738-5.339-1.562-5.367-4.459-.028-2.783 2.272-4.771 5.737-4.771 2.101 0 4.175.738 5.793 1.818l-1.675 2.954c-1.335-.824-2.868-1.306-4.118-1.306-1.023 0-1.732.369-1.732.88 0 .795.596 1.022 2.584 1.562 2.527.653 5.339 1.505 5.339 4.714 0 2.897-2.357 4.743-5.964 4.743-2.358 0-4.857-.796-6.618-2.216'
            ></path>
            <path
                fill='#fff'
                d='M105.558 29.988c-.845.767-2.17 1.278-3.353 1.278-2.565 0-4.368-1.846-4.368-4.487v-7.185H95.83V16.25h2.006v-4.012h3.946v4.012h3.21v3.344h-3.21v6.532c0 .966.62 1.647 1.381 1.647.564 0 1.127-.199 1.409-.483l.986 2.698z'
            ></path>
            <path
                fill='#C72128'
                d='M105.203 11.235L108.386 11.235 113.19 26.129 118.079 11.235 121.262 11.235 126.151 26.129 130.955 11.235 134.138 11.235 127.714 30.762 124.844 30.762 119.699 15.214 114.497 30.762 111.627 30.762z'
            ></path>
            <path
                fill='#C72128'
                d='M146.053 21.873v8.889h-2.742v-2.016c-1.074 1.533-3.081 2.357-4.862 2.357-3.109 0-5.399-1.817-5.399-4.686 0-2.925 2.629-4.885 5.851-4.885 1.442 0 2.996.312 4.41.795v-.454c0-1.846-.848-3.805-3.76-3.805-1.442 0-2.855.568-4.127 1.221l-1.018-2.073c2.092-1.051 3.929-1.477 5.54-1.477 3.93 0 6.107 2.499 6.107 6.134m-2.742 4.273v-1.959c-1.193-.369-2.556-.568-4.004-.568-1.96 0-3.607 1.108-3.607 2.698 0 1.59 1.42 2.556 3.266 2.556 1.817-.001 3.862-.91 4.345-2.727'
            ></path>
            <path
                fill='#C72128'
                d='M146.79 16.05L149.835 16.05 154.417 27.489 158.913 16.05 161.958 16.05 155.754 30.762 153.108 30.762z'
            ></path>
            <path
                fill='#C72128'
                d='M173.509 27.022l1.761 1.818c-1.306 1.392-3.862 2.272-5.935 2.272-4.09 0-7.554-3.209-7.554-7.725 0-4.402 3.266-7.639 7.327-7.639 4.487 0 7.071 3.408 7.071 8.662H164.62c.369 2.442 2.158 4.203 4.686 4.203 1.533-.001 3.323-.683 4.203-1.591m-8.861-4.887h8.804c-.312-2.272-1.704-3.919-4.231-3.919-2.272 0-4.118 1.534-4.573 3.919'
            ></path>
            <path
                fill='#C72128'
                d='M39.003 16.05h-1.721a.882.882 0 01-.882-.882c.002-1.001-.067-1.986-1.803-2.194h-2.364v2.42a.656.656 0 01-.656.656h-2.881a.676.676 0 01-.676-.676V5.856a1.843 1.843 0 00-1.843-1.843h-.42c-1.04 0-1.883.843-1.883 1.883v20.779c0 .817-.662 1.479-1.479 1.479h-1.539a.43.43 0 01-.415-.319c-.115-.418-.145-1.064-1.466-1.152h-1.362c-.752 0-1.362.526-1.362 1.175v13.179c0 1.084.879 1.963 1.963 1.963h.179a2.004 2.004 0 002.004-2.004V32.15c0-.545.442-.987.987-.987h1.392c.57 0 1.032.462 1.032 1.032v4.394c.009.066.02 1.321.02 1.321 0 1.039.848 1.88 1.895 1.88h.374a1.923 1.923 0 001.923-1.923v-18.36a.58.58 0 01.581-.581h2.981c.36 0 .651.292.651.651v9.035c0 1.04.843 1.883 1.883 1.883h2.263V19.976c0-.58.47-1.051 1.051-1.051h2.761v-1.687c0-.656-.532-1.188-1.188-1.188'
            ></path>
            <path
                fill='#fff'
                d='M19.995 1.763C19.995.789 19.206 0 18.232 0h-.94c-.797 0-1.443.646-1.443 1.443v9.091a.97.97 0 01-.969.969h-1.549a.897.897 0 01-.893-.9l-.02-3.905a1.889 1.889 0 00-1.895-1.882h-.374a1.923 1.923 0 00-1.923 1.923V23.66c0 .34-.276.616-.616.616H4.762a.616.616 0 01-.616-.616v-9.55H1.763C.789 14.11 0 14.9 0 15.874v15.423h2.263c1.04 0 1.883-.843 1.883-1.883v-1.505a.69.69 0 01.691-.691h2.622c.423 0 .766.343.766.766v10.645c0 1.084.879 1.963 1.963 1.963h.299c1.04 0 1.883-.843 1.883-1.883V15.548a.97.97 0 01.97-.97h2.1c.162 0 .3.109.345.265.18.616.833 1.073 1.617 1.073h.824c.976 0 1.768-.67 1.768-1.496V1.763z'
            ></path>
        </svg>
    );
}

export default BlastWaveLogo;
