import useResizeObserver from '@react-hook/resize-observer';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import QRCode from 'react-qr-code';
import { useNavigate } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import styled from 'styled-components';
import { Button, Subtitle, Title } from './style';
import './Switch.css';

const Root = styled.div`
    display: grid;
    grid-template: 'main';
    > * {
        grid-area: main;
        position: relative;
    }
`;

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-left: 100px;
    margin-right: 100px;
    text-align: center;
`;

const Circle = styled.div`
    width: 50vh;
    min-width: 256px;
    height: 50vh;
    min-height: 256px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    background-color: white;
    border-radius: 50%;
`;

const STATE_RECEIVING_SESSION = 1;
const STATE_WAITING_FOR_REMOTE = 2;
const STATE_CONNECTED = 3;
const STATE_WAITING_FOR_AUTH = 4;
const STATE_REGISTRATION_FAILED = 5;

const stateMessages = {
    [STATE_RECEIVING_SESSION]: 'Waiting for session token',
    [STATE_WAITING_FOR_REMOTE]: '',
    [STATE_CONNECTED]: 'Connection to mobile app established, sending invitation...',
    [STATE_WAITING_FOR_AUTH]: 'Please complete the registration process in the BlastShield Authenticator app',
    [STATE_REGISTRATION_FAILED]: 'Registration failed, retry?',
};

const CircledQRCode = ({ value }) => {
    const [circleSize, setCircleSize] = useState(128);
    const circleRef = useRef(null);

    useResizeObserver(circleRef, (e) => {
        setCircleSize(e.contentRect.width);
    });

    return (
        <Circle ref={circleRef}>
            <QRCode size={circleSize * 0.6} value={value} style={{ shapeRendering: 'crispEdges' }} />
        </Circle>
    );
};

const Register = ({ invitation }) => {
    const ws = useRef(null);
    const [state, setState] = useState(STATE_RECEIVING_SESSION);
    const [session, setSession] = useState('');
    const navigate = useNavigate();

    const sendAuthRequest = useCallback(() => {
        const cmd = 'invitation';

        const msg = {
            cmd,
            args: {
                invitation,
            },
        };
        ws.current.send(JSON.stringify(msg));
        setState(STATE_WAITING_FOR_AUTH);
    }, [invitation]);

    const onMessage = useCallback(
        (e) => {
            const handleRemoteMessage = (msg) => {
                switch (msg.cmd) {
                    case 'network_list':
                        sendAuthRequest();
                        break;
                    case 'invitation_result':
                        if (msg.args.registered) {
                            ws.current.send(JSON.stringify({ cmd: 'success' }));
                            ws.current.onclose = null;
                            ws.current.close();
                            navigate('/success');
                        } else {
                            setState(STATE_REGISTRATION_FAILED);
                        }
                        break;
                    default:
                        break;
                }
            };

            switch (state) {
                case STATE_RECEIVING_SESSION:
                    setSession(e.data);
                    setState(STATE_WAITING_FOR_REMOTE);
                    break;
                case STATE_WAITING_FOR_REMOTE:
                    setState(STATE_CONNECTED);
                // fall through
                case STATE_CONNECTED:
                case STATE_WAITING_FOR_AUTH:
                    handleRemoteMessage(JSON.parse(e.data));
                    break;
                default:
                    break;
            }
        },
        [state, sendAuthRequest, navigate]
    );

    useEffect(() => {
        if (ws.current == null) {
            ws.current = new WebSocket('wss://auth.blastwave.io:443/new');
            ws.current.onopen = () => {
                console.log('Connected');
            };
            ws.current.onclose = () => {
                console.log('onClose');
                ws.current = undefined;
                setState(STATE_RECEIVING_SESSION);
                // showMessage('Error', 'Registration cancelled by mobile app, please try again.');
            };
        }
        ws.current.onmessage = onMessage;
    }, [onMessage]);

    useEffect(() => {
        return () => {
            if (ws.current) ws.current.close();
        };
    }, []);

    return (
        <TransitionGroup component={Root}>
            <CSSTransition key={state} classNames='switch' timeout={10000}>
                {state === STATE_WAITING_FOR_REMOTE ? (
                    <Container>
                        <Title>SCAN QR CODE</Title>
                        <Subtitle>
                            Please scan the QR code with your <b>Blast</b>Shield™ Authenticator App
                        </Subtitle>

                        <CircledQRCode value={`BS-Auth:${session}`} />
                    </Container>
                ) : (
                    <Container>
                        <Title>{stateMessages[state]}</Title>
                        {state === STATE_REGISTRATION_FAILED && <Button onClick={sendAuthRequest}>RETRY</Button>}
                    </Container>
                )}
            </CSSTransition>
        </TransitionGroup>
    );
};

export default Register;
